﻿/* DEFAULTS
----------------------------------------------------------*/
body {
    background: url('/images/bg.jpg') repeat-x top #fff;
    color: #2a2a2a;
}

.page-background {
    background-image: url('/images/bubble.png');
    background-position: top;
    background-repeat: repeat-x;
    width: 100%;
}

#main {
    background-color: #fff;
    padding-bottom: 20px;
}

h2, h3, h4 {
    color: #628201;
}

h3:first-child {
    margin-top: 0;
}

.rbllist label {
    margin-left: 4px;
    display: inline;
}

/* Header */

#masthead {
    #logo {
        padding-top: 10px;
        padding-bottom: 6px;
    }
}


/* PRIMARY LAYOUT ELEMENTS   
----------------------------------------------------------*/

#header {
    background: #4b6c9e;
}

#banner-area {
    padding-top: 10px;
    margin-bottom: 10px;
}

.page-header {
    margin-top: auto;

    h1, h2 {
        color: #08387f;
    }
}

.footer {
    color: #4e5766;
    padding: 8px 0px 0px 0px;
    margin: 0px auto;
    text-align: center;
    line-height: normal;
}

.post {
    margin-bottom: 1em;
    border-bottom: solid 1px #ddd;
    padding-bottom: 1em;

    h1 {
        margin-bottom: 10px;
    }

    .tagline {
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .postfooter {
        margin: 10px 0 0 0;
        text-align: left;
        font-size: 12px;
    }

    .postbottom {
        margin: 10px 0;
        text-align: left;
        font-size: 12px;
    }
}


.widget {
    border: solid 1px #d5d5d5;
    background: #f7f7f7;
    margin-bottom: 1em;

    &.first {
        margin-top: 4em;
    }

    &.clean {
        border: none;
        background: none;
    }

    &.img {
        border: none;
        background: none;
    }

    &.img img {
        display: block;
        max-width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }

    h3 {
        background: #e7f1ff;
        margin: 0;
        border-bottom: solid 1px #d5d5d5;
        padding: 4px 8px;
        color: #08387f;
        font-size: 14px;
    }

    .inside {
        padding: 1em 8px;

        li {
            margin-left: 1em;
        }

        ul, ol {
            margin-bottom: 0;
            padding-left: 1em;
        }
    }

    .content.inside {
        font-size: 12px;
    }

    &.categorylist .rssButton {
        display: none;
    }

    &.categorylist ul {
        margin: 0 0 0 20px;
        padding: 0;
        list-style: none;
        font-size: 12px;
    }

    &.categorylist ul li {
        margin: 0;
        padding: 0;
        list-style: disc outside;
        font-size: 12px;
    }
}


/* TAB MENU   
----------------------------------------------------------*/

div.hideSkiplink {
    background-color: #3a4f63;
    width: 100%;
}

div.menu {
    padding: 4px 0px 4px 8px;

    ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
        width: auto;

        li {

            a, a:visited {
                background-color: #465c71;
                border: 1px #4e667d solid;
                color: #dde4ec;
                display: block;
                line-height: 1.35em;
                padding: 4px 20px;
                text-decoration: none;
                white-space: nowrap;
            }

            a:hover {
                background-color: #bfcbd6;
                color: #465c71;
                text-decoration: none;
            }

            a:active {
                background-color: #465c71;
                color: #cfdbe6;
                text-decoration: none;
            }
        }
    }
}


/* Footer */
#bottom_section {
    background: #f2f2f2; /* Old browsers */
    background: -moz-linear-gradient(top, #ffffff 0%, #f2f2f2 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#f2f2f2)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ffffff 0%,#f2f2f2 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ffffff 0%,#f2f2f2 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ffffff 0%,#f2f2f2 100%); /* IE10+ */
    background: linear-gradient(top, #ffffff 0%,#f2f2f2 100%); /* W3C */
    background-repeat: repeat-x;
    background-position: bottom;
    border-top: solid 4px #08387f;
    border-bottom: solid 1px #dfdfdf;
}

#footer {
    padding-top: 20px;
    padding-bottom: 20px;

    h5, h5 a {
        color: #004567;
        font-size: 14px;
        margin-bottom: 12px;
        font-weight: bold;
        text-decoration: none;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        background: url('/images/listarrow.gif') no-repeat left 5px;
        padding: 0 0 0 15px;
        margin: 0;
        font-size: 11px;

        &#telephone_no {
            background-image: url('/images/tele.gif');
            background-position: left 5px;
            padding: 0 0 0 20px;
        }

        &#mailing_address {
            background-image: url('/images/mail.gif');
            background-position: left 2px;
            padding: 0 0 0 20px;
        }

        &#email_address {
            background-image: url('/images/email.gif');
            background-position: left 0px;
            padding: 0 0 0 20px;
        }

        a {
            font-weight: bold;
            color: #818181;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}


#pagebottom {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #999999;
    font-size: 12px;
}

/* FORM ELEMENTS   
----------------------------------------------------------*/

input.textEntry {
    width: 320px;
    border: 1px solid #ccc;
}

input.passwordEntry {
    width: 320px;
    border: 1px solid #ccc;
}

div.accountInfo {
    width: 42%;
}

/* MISC  
----------------------------------------------------------*/


.loginDisplay {
    font-size: 1.1em;
    display: block;
    text-align: right;
    padding: 10px;
    color: White;

    a:link {
        color: white;
    }

    a:visited {
        color: white;
    }

    a:hover {
        color: white;
    }
}

.failureNotification {
    font-size: 1.2em;
    color: Red;
}

.bold {
    font-weight: bold;
}

.submitButton {
    text-align: right;
    padding-right: 10px;
}

#mast-register {
    margin-top: 11px;
}

#mast-login {
    margin-top: 11px;
}

.regtable {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    td {
        padding: 2px 4px;
    }

    .regcaption {
        width: 180px;
    }
}

.regarea {
    margin-bottom: 1em;

    table {
        margin-bottom: 0;
    }
}

.regsubhead {
    background-color: #F4FFD0;
    font-weight: bold;
    font-size: 16px;
    color: #0086B2;
    padding: 2px 4px;
}

.reginfo {
    margin: 4px 40px;
}

.regbutton {
    text-align: center;
    margin: 1em auto;
}
/* Pricing table */
.pricetable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;

    td, th {
        padding: 4px 8px;
        border: solid thin #BBC1C4;
        line-height: 14px;
    }

    th {
        color: #0086B2;
        font-weight: bold;
        background-color: #F4FFD0;
    }

    .button {
        text-align: center;
        width: 160px;
    }

    .price {
        text-align: center;
    }

    .name {
        font-weight: bold;
    }

    .register {
        text-align: center;
    }
}



#seals-right {

    .seal-row {
        text-align: center;
        margin: 1em auto;
    }
}


@media (max-width: 767px) {

    .header-mobile {
        margin-bottom: 10px;
        margin-top: 10px;

        img {
            display: block;
            width: 100% \9;
            max-width: 100%;
            height: auto;
        }

        .bottombutton {
            margin-top: 5px;
        }
    }
}
/* cart */
.regsum {
    width: 100%;
    border-collapse: collapse;

    .regsumdesc {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-right: 8px;
        width: 75%;
    }

    .regsumprice {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 8px;
        text-align: right;
        width: 25%;
    }

    .regsumdescfoot {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-right: 8px;
        border-top: solid 1px black;
        font-weight: bold;
    }

    .regsumpricefoot {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 8px;
        text-align: right;
        border-top: solid 1px black;
        font-weight: bold;
    }
}
