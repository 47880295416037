﻿.navbar {
    margin-bottom: 0 !important;
}

/* http://work.smarchal.com/twbscolor/scss/0086b289ab20ffffffffffff0 */
.navbar-default {
    background-color: #0086b2;
    border-color: #89ab20;

    .navbar-brand {
        color: #ffffff;

        &:hover, &:focus {
            color: #ffffff;
        }
    }

    .navbar-text {
        color: #ffffff;
    }

    .navbar-nav {
        > li > a {
            color: #ffffff;

            &:hover, &:focus {
                color: #ffffff;
            }
        }

        > .active {
            > a, > a:hover, > a:focus {
                color: #ffffff;
                background-color: #89ab20;
            }
        }

        > .open {
            > a, > a:hover, > a:focus {
                color: #ffffff;
                background-color: #89ab20;
            }
        }
    }

    .navbar-toggle {
        border-color: #89ab20;

        &:hover, &:focus {
            background-color: #89ab20;
        }

        .icon-bar {
            background-color: #ffffff;
        }
    }

    .navbar-collapse,
    .navbar-form {
        border-color: #ffffff;
    }

    .navbar-link {
        color: #ffffff;

        &:hover {
            color: #ffffff;
        }
    }

    @media (max-width: 767px) {
        .navbar-nav {
            .open .dropdown-menu {
                > li > a {
                    color: #ffffff;
                }

                > li > a:hover, > li > a:focus {
                    color: #ffffff;
                }

                > .active > a, > .active > a:hover, > .active > a:focus {
                    color: #ffffff;
                    background-color: #89ab20;
                }
            }
        }
    }
}
